const languages = [
    { id: 1, code: 'ab', name: 'Abkhazian' },
    { id: 2, code: 'aa', name: 'Afar' },
    { id: 3, code: 'af', name: 'Afrikaans' },
    { id: 4, code: 'ak', name: 'Akan' },
    { id: 5, code: 'sq', name: 'Albanian' },
    { id: 6, code: 'am', name: 'Amharic' },
    { id: 7, code: 'ar', name: 'Arabic' },
    { id: 8, code: 'an', name: 'Aragonese' },
    { id: 9, code: 'hy', name: 'Armenian' },
    { id: 10, code: 'as', name: 'Assamese' },
    { id: 11, code: 'zu', name: 'Zulu' },
    { id: 12, code: 'av', name: 'Avaric' },
    { id: 13, code: 'ae', name: 'Avestan' },
    { id: 14, code: 'ay', name: 'Aymara' },
    { id: 15, code: 'az', name: 'Azerbaijani' },
    { id: 16, code: 'bm', name: 'Bambara' },
    { id: 17, code: 'ba', name: 'Bashkir' },
    { id: 18, code: 'eu', name: 'Basque' },
    { id: 19, code: 'be', name: 'Belarusian' },
    { id: 20, code: 'bn', name: 'Bengali' },
    { id: 21, code: 'bh', name: 'Bihari languages' },
    { id: 22, code: 'bi', name: 'Bislama' },
    { id: 23, code: 'bs', name: 'Bosnian' },
    { id: 24, code: 'br', name: 'Breton' },
    { id: 25, code: 'bg', name: 'Bulgarian' },
    { id: 26, code: 'my', name: 'Burmese' },
    { id: 27, code: 'ca', name: 'Catalan, Valencian' },
    { id: 28, code: 'km', name: 'Central Khmer' },
    { id: 29, code: 'ch', name: 'Chamorro' },
    { id: 30, code: 'ce', name: 'Chechen' },
    { id: 31, code: 'ny', name: 'Chichewa, Chewa, Nyanja' },
    { id: 32, code: 'zh', name: 'Chinese' },
    { id: 33, code: 'cu', name: 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
    { id: 34, code: 'cv', name: 'Chuvash' },
    { id: 35, code: 'kw', name: 'Cornish' },
    { id: 36, code: 'co', name: 'Corsican' },
    { id: 37, code: 'cr', name: 'Cree' },
    { id: 38, code: 'hr', name: 'Croatian' },
    { id: 39, code: 'cs', name: 'Czech' },
    { id: 40, code: 'da', name: 'Danish' },
    { id: 41, code: 'dv', name: 'Divehi, Dhivehi, Maldivian' },
    { id: 42, code: 'nl', name: 'Dutch, Flemish' },
    { id: 43, code: 'dz', name: 'Dzongkha' },
    { id: 44, code: 'en', name: 'English' },
    { id: 45, code: 'eo', name: 'Esperanto' },
    { id: 46, code: 'et', name: 'Estonian' },
    { id: 47, code: 'ee', name: 'Ewe' },
    { id: 48, code: 'fo', name: 'Faroese' },
    { id: 49, code: 'fj', name: 'Fijian' },
    { id: 50, code: 'fi', name: 'Finnish' },
    { id: 51, code: 'fr', name: 'French' },
    { id: 52, code: 'ff', name: 'Fulah' },
    { id: 53, code: 'gd', name: 'Gaelic, Scottish Gaelic' },
    { id: 54, code: 'gl', name: 'Galician' },
    { id: 55, code: 'lg', name: 'Ganda' },
    { id: 56, code: 'ka', name: 'Georgian' },
    { id: 57, code: 'de', name: 'German' },
    { id: 58, code: 'ki', name: 'Gikuyu, Kikuyu' },
    { id: 59, code: 'el', name: 'Greek (Modern)' },
    { id: 60, code: 'kl', name: 'Greenlandic, Kalaallisut' },
    { id: 61, code: 'gn', name: 'Guarani' },
    { id: 62, code: 'gu', name: 'Gujarati' },
    { id: 63, code: 'ht', name: 'Haitian, Haitian Creole' },
    { id: 64, code: 'ha', name: 'Hausa' },
    { id: 65, code: 'he', name: 'Hebrew' },
    { id: 66, code: 'hz', name: 'Herero' },
    { id: 67, code: 'hi', name: 'Hindi' },
    { id: 68, code: 'ho', name: 'Hiri Motu' },
    { id: 69, code: 'hu', name: 'Hungarian' },
    { id: 70, code: 'is', name: 'Icelandic' },
    { id: 71, code: 'io', name: 'Ido' },
    { id: 72, code: 'ig', name: 'Igbo' },
    { id: 73, code: 'id', name: 'Indonesian' },
    { id: 74, code: 'ia', name: 'Interlingua (International Auxiliary Language Association)' },
    { id: 75, code: 'ie', name: 'Interlingue' },
    { id: 76, code: 'iu', name: 'Inuktitut' },
    { id: 77, code: 'ik', name: 'Inupiaq' },
    { id: 78, code: 'ga', name: 'Irish' },
    { id: 79, code: 'it', name: 'Italian' },
    { id: 80, code: 'ja', name: 'Japanese' },
    { id: 81, code: 'jv', name: 'Javanese' },
    { id: 82, code: 'kn', name: 'Kannada' },
    { id: 83, code: 'kr', name: 'Kanuri' },
    { id: 84, code: 'ks', name: 'Kashmiri' },
    { id: 85, code: 'kk', name: 'Kazakh' },
    { id: 86, code: 'rw', name: 'Kinyarwanda' },
    { id: 87, code: 'kv', name: 'Komi' },
    { id: 88, code: 'kg', name: 'Kongo' },
    { id: 89, code: 'ko', name: 'Korean' },
    { id: 90, code: 'kj', name: 'Kwanyama, Kuanyama' },
    { id: 91, code: 'ku', name: 'Kurdish' },
    { id: 92, code: 'ky', name: 'Kyrgyz' },
    { id: 93, code: 'lo', name: 'Lao' },
    { id: 94, code: 'la', name: 'Latin' },
    { id: 95, code: 'lv', name: 'Latvian' },
    { id: 96, code: 'lb', name: 'Letzeburgesch, Luxembourgish' },
    { id: 97, code: 'li', name: 'Limburgish, Limburgan, Limburger' },
    { id: 98, code: 'ln', name: 'Lingala' },
    { id: 99, code: 'lt', name: 'Lithuanian' },
    { id: 100, code: 'lu', name: 'Luba-Katanga' },
    { id: 101, code: 'mk', name: 'Macedonian' },
    { id: 102, code: 'mg', name: 'Malagasy' },
    { id: 103, code: 'ms', name: 'Malay' },
    { id: 104, code: 'ml', name: 'Malayalam' },
    { id: 105, code: 'mt', name: 'Maltese' },
    { id: 106, code: 'gv', name: 'Manx' },
    { id: 107, code: 'mi', name: 'Maori' },
    { id: 108, code: 'mr', name: 'Marathi' },
    { id: 109, code: 'mh', name: 'Marshallese' },
    { id: 110, code: 'ro', name: 'Moldovan, Moldavian, Romanian' },
    { id: 111, code: 'mn', name: 'Mongolian' },
    { id: 112, code: 'na', name: 'Nauru' },
    { id: 113, code: 'nv', name: 'Navajo, Navaho' },
    { id: 114, code: 'nd', name: 'Northern Ndebele' },
    { id: 115, code: 'ng', name: 'Ndonga' },
    { id: 116, code: 'ne', name: 'Nepali' },
    { id: 117, code: 'se', name: 'Northern Sami' },
    { id: 118, code: 'no', name: 'Norwegian' },
    { id: 119, code: 'nb', name: 'Norwegian Bokmål' },
    { id: 120, code: 'nn', name: 'Norwegian Nynorsk' },
    { id: 121, code: 'ii', name: 'Nuosu, Sichuan Yi' },
    { id: 122, code: 'oc', name: 'Occitan (post 1500)' },
    { id: 123, code: 'oj', name: 'Ojibwa' },
    { id: 124, code: 'or', name: 'Oriya' },
    { id: 125, code: 'om', name: 'Oromo' },
    { id: 126, code: 'os', name: 'Ossetian, Ossetic' },
    { id: 127, code: 'pi', name: 'Pali' },
    { id: 128, code: 'pa', name: 'Panjabi, Punjabi' },
    { id: 129, code: 'ps', name: 'Pashto, Pushto' },
    { id: 130, code: 'fa', name: 'Persian' },
    { id: 131, code: 'pl', name: 'Polish' },
    { id: 132, code: 'pt', name: 'Portuguese' },
    { id: 133, code: 'qu', name: 'Quechua' },
    { id: 134, code: 'rm', name: 'Romansh' },
    { id: 135, code: 'rn', name: 'Rundi' },
    { id: 136, code: 'ru', name: 'Russian' },
    { id: 137, code: 'sm', name: 'Samoan' },
    { id: 138, code: 'sg', name: 'Sango' },
    { id: 139, code: 'sa', name: 'Sanskrit' },
    { id: 140, code: 'sc', name: 'Sardinian' },
    { id: 141, code: 'sr', name: 'Serbian' },
    { id: 142, code: 'sn', name: 'Shona' },
    { id: 143, code: 'sd', name: 'Sindhi' },
    { id: 144, code: 'si', name: 'Sinhala, Sinhalese' },
    { id: 145, code: 'sk', name: 'Slovak' },
    { id: 146, code: 'sl', name: 'Slovenian' },
    { id: 147, code: 'so', name: 'Somali' },
    { id: 148, code: 'st', name: 'Sotho, Southern' },
    { id: 149, code: 'nr', name: 'South Ndebele' },
    { id: 150, code: 'es', name: 'Spanish, Castilian' },
    { id: 151, code: 'su', name: 'Sundanese' },
    { id: 152, code: 'sw', name: 'Swahili' },
    { id: 153, code: 'ss', name: 'Swati' },
    { id: 154, code: 'sv', name: 'Swedish' },
    { id: 155, code: 'tl', name: 'Tagalog' },
    { id: 156, code: 'ty', name: 'Tahitian' },
    { id: 157, code: 'tg', name: 'Tajik' },
    { id: 158, code: 'ta', name: 'Tamil' },
    { id: 159, code: 'tt', name: 'Tatar' },
    { id: 160, code: 'te', name: 'Telugu' },
    { id: 161, code: 'th', name: 'Thai' },
    { id: 162, code: 'bo', name: 'Tibetan' },
    { id: 163, code: 'ti', name: 'Tigrinya' },
    { id: 164, code: 'to', name: 'Tonga (Tonga Islands)' },
    { id: 165, code: 'ts', name: 'Tsonga' },
    { id: 166, code: 'tn', name: 'Tswana' },
    { id: 167, code: 'tr', name: 'Turkish' },
    { id: 168, code: 'tk', name: 'Turkmen' },
    { id: 169, code: 'tw', name: 'Twi' },
    { id: 170, code: 'ug', name: 'Uighur, Uyghur' },
    { id: 171, code: 'uk', name: 'Ukrainian' },
    { id: 172, code: 'ur', name: 'Urdu' },
    { id: 173, code: 'uz', name: 'Uzbek' },
    { id: 174, code: 've', name: 'Venda' },
    { id: 175, code: 'vi', name: 'Vietnamese' },
    { id: 176, code: 'vo', name: 'Volap_k' },
    { id: 177, code: 'wa', name: 'Walloon' },
    { id: 178, code: 'cy', name: 'Welsh' },
    { id: 179, code: 'fy', name: 'Western Frisian' },
    { id: 180, code: 'wo', name: 'Wolof' },
    { id: 181, code: 'xh', name: 'Xhosa' },
    { id: 182, code: 'yi', name: 'Yiddish' },
    { id: 183, code: 'yo', name: 'Yoruba' },
    { id: 184, code: 'za', name: 'Zhuang, Chuang' },

];

export default languages;