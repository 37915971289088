import "./App.css";
import React, { Component } from 'react';
import './App.css';
import {
    MenuItem,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    LinearProgress,
    Box,
    Typography,
    IconButton,
    Select
} from '@mui/material';
import { BlockBlobClient } from '@azure/storage-blob';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import http from "./http-common";
import { v4 as uuidv4 } from 'uuid';
import languages from './languageCode';
import FILE_TYPES from "./Enums";

const BorderLinearProgress = styled(LinearProgress)({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
})

export default class App extends Component {
    languageCodes;
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: [],
            progress: 0,
            videoSasUrl: '',
            subtitleSasUrl: '',
            videoAssetId: '',
            subtitleAssetId: '',
            uploadType: '',
            expanded: 'panel0',
            videoUploadStatus: false,
            subtitleUploadStatus: false,
            subTitleFileName: '',
            uploading: 'video1',
            uploadDisabled: false,
            jobId: ''
        };
        this.onSelectFiles = this.onSelectFiles.bind(this);
        this.onChangeSubtitleFile = this.onChangeSubtitleFile.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.languageCodes = languages;
    }

    handleChange =
        (panel) => (event, isExpanded) => {
            this.setState({ expanded: (isExpanded ? panel : false) });
        };

    onSelectFiles(event, type) {
        const files = [...event?.target?.files]
        const selectedFiles = (files || []).map((selectedFile) => {
            let assetSchema = {
                file: selectedFile,
                fileName: this.getFileNameAndExt(selectedFile?.name).fileName,
                fileProgress: [],
                fileType: type,
                subtitleSchemas: []
            };
            type === FILE_TYPES.VIDEO && assetSchema.subtitleSchemas.push({
                subtitleFile: '',
                subtitleFileName: '',
                srtFileName: '',
                languageCode: '',
                subtitleProgress: '',
                fileType: FILE_TYPES.SUBTITLE,
            });
            return assetSchema;
        })
        this.setState({
            selectedFiles
        });
    }

    addSubTitle = (index, i) => {
        let subtitleSchema = {
            subtitleFile: '',
            subtitleFileName: '',
            srtFileName: '',
            languageCode: '',
            subtitleProgress: '',
            fileType: FILE_TYPES.SUBTITLE
        }
        let assetSchemas = [...this.state.selectedFiles];
        assetSchemas[index].subtitleSchemas.push(subtitleSchema);
        this.setState({ selectedFiles: assetSchemas });
    }

    removeAsset = (index) => {
        let assetSchemas = [...this.state.selectedFiles];
        assetSchemas.splice(index, 1);
        this.setState({ selectedFiles: assetSchemas });
    }

    removeSubtitle = (index, i) => {
        let assetSchemas = [...this.state.selectedFiles];
        if (assetSchemas[index].subtitleSchemas.length > 1) {
            assetSchemas[index].subtitleSchemas.splice(i, 1);
            this.setState({ selectedFiles: assetSchemas });
        }
    }

    onChangeFile = (event, index) => {
        const selectedFile = event?.target?.files[0];
        let assetSchemas = [...this.state.selectedFiles];
        assetSchemas[index].file = selectedFile;
        assetSchemas[index].fileName = this.getFileNameAndExt(selectedFile?.name).fileName;
        this.setState({ selectedFiles: assetSchemas });
    }

    onChangeSubtitleFile = (event, assetI, i) => {
        const selectedFile = event?.target?.files[0];
        let assetSchemas = [...this.state.selectedFiles];
        assetSchemas[assetI].subtitleSchemas[i].subtitleFile = selectedFile;
        assetSchemas[assetI].subtitleSchemas[i].subtitleFileName = this.getFileNameAndExt(selectedFile?.name).fileName;
        if (selectedFile.type === FILE_TYPES.SRTFILE)
            assetSchemas[assetI].subtitleSchemas[i].srtFileName = assetSchemas[assetI].fileName + '.srt';
        this.setState({ selectedFiles: assetSchemas });
    }


    handleChangeFileName = (event, index) => {
        let assetSchemas = [...this.state.selectedFiles];
        assetSchemas[index].fileName = event?.target?.value;
        this.setState({ selectedFiles: assetSchemas });
    }

    handleChangeSubtitleFileName = (event, index, i) => {
        let assetSchemas = [...this.state.selectedFiles];
        assetSchemas[index].subtitleSchemas[i].subtitleFileName = event?.target?.value;
        this.setState({ selectedFiles: assetSchemas });
    }

    handleCodeChange = (event, index, i) => {
        let assetSchemas = [...this.state.selectedFiles];
        assetSchemas[index].subtitleSchemas[i].languageCode = event?.target?.value;
        this.setState({ selectedFiles: assetSchemas });
    };

    getFileNameAndExt = (text) => {
        var index = text?.lastIndexOf('.');
        return { fileName: text?.slice(0, index), fileExt: text?.slice(index + 1) }
    }

    createAsset = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        console.log('token', token);
        return new Promise((resolve, reject) => {
            http.post(`/create_assets?token=${token}`, {}).then((resData) => {
                console.log('createAssetData', JSON.parse(resData?.data));
                const { inputAsset = {} } = JSON.parse(resData?.data);
                const videoAssetId = inputAsset.name;
                const videoSasUrl = inputAsset?.inputAssetSasUrl?.assetContainerSasUrls[0];
                const subtitleAssetId = inputAsset.outputAsset.name;
                const subtitleSasUrl = inputAsset?.outputAssetSasUrl?.assetContainerSasUrls[0];
                this.setState({
                    videoAssetId,
                    videoSasUrl,
                    subtitleAssetId,
                    subtitleSasUrl,
                }, () => {
                    resolve({
                        videoAssetId,
                        videoSasUrl,
                        subtitleAssetId,
                        subtitleSasUrl,
                    });
                });
            })
                .catch((err) => {
                    this.setState({
                        progress: 0,
                    });
                    toast.error(
                        `Could not get SAS URL!`, {
                        draggablePercent: 60
                    });
                    reject(`Could not get SAS URL!`);
                });
        })

    }


    createJob = (schema) => {
        const { videoAssetId, subtitleAssetId, subTitleFileName, jobId } = this.state;
        const { subtitleFile, fileType, srtFileName } = schema;
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        let formData = new FormData();
        if (fileType === FILE_TYPES.SUBTITLE) {
            formData.append("file", subtitleFile);
        }
        const url = fileType !== FILE_TYPES.SUBTITLE ?
            `/create_jobs?assetId=${videoAssetId}&output_asset_id=${subtitleAssetId}&token=${token}&frontAsset=${this.replaceSpaceWithUnderscore(schema?.fileName)}&fileType=${schema?.fileType}`
            : `/create_subtitle_job?token=${token}&output_asset_id=${subtitleAssetId}&file_name=${this.replaceSpaceWithUnderscore(subTitleFileName)}&display_name=${this.replaceSpaceWithUnderscore(schema?.subtitleFileName)}&language_code=${schema?.languageCode}&jobid=${jobId}`

        return new Promise((resolve, reject) => {
            http.post(url, fileType === FILE_TYPES.SUBTITLE ? formData : {}).then(async (resData) => {
                console.log('createjob Type:', fileType);
                if (fileType === FILE_TYPES.VIDEO) {
                    console.log('createjobs respoceData', resData);
                    this.setState({
                        jobId: resData?.data?.locator_name
                    }, () => {
                        resolve({ success: true });
                    })
                } else {
                    resolve({ success: true });
                }
            }).catch(() => {
                toast.error(
                    `Could not create the job!`, {
                    draggablePercent: 60
                });
                reject({ success: false });
            });
        })
    }

    asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    onUpload = () => {
        let { selectedFiles } = this.state;
        if (selectedFiles.every(element => element.fileName)) {
            this.setState({ uploadDisabled: true });
            this.asyncForEach(selectedFiles, async (assetSchema, index) => {

                this.setState({ uploading: ('video' + index), progress: 0 });
                await this.createAsset();
                const { success } = await this.uploadVideoFile(assetSchema);
                await this.createJob(assetSchema);
                selectedFiles[index].fileProgress.push(`${assetSchema?.fileType}:${success ? '100%' : 'Failed'}`);
                this.setState({ selectedFiles });

                await this.asyncForEach(assetSchema?.subtitleSchemas, async (schema, i) => {

                    if (schema?.subtitleFile) {
                        const { success } = await this.uploadSubTitle(schema);
                        await this.createJob(schema);
                        selectedFiles[index].fileProgress.push(`Subtitle${i + 1}:${success ? '100%' : 'Failed'}`);
                        this.setState({ selectedFiles });
                    }
                });
            });
        } else {
            toast.error("File Name should not be empty.");
        }
    }

    replaceSpaceWithUnderscore = (inputString)=> {
        return inputString.replace(/\s/g, '_');
      }

    uploadVideoFile = (assetSchema) => {
        const { videoSasUrl } = this.state;
        const { fileName, file } = assetSchema;

        return new Promise((resolve, reject) => {
            this.uploadFile(
                assetSchema,
                this.replaceSpaceWithUnderscore(fileName),
                (event) => {
                    this.setState({
                        progress: Math.round((100 * event.loadedBytes) / file.size),
                    });
                },
                videoSasUrl
            ).then((responce) => {
                resolve({ success: true })
            }).catch((error) => {
                reject({ success: false });
            });
        });
    }

    uploadSubTitle = (schema) => {
        const { subtitleSasUrl } = this.state;
        let { subtitleFile, srtFileName } = schema;
        const fileNameAndExt = this.getFileNameAndExt((subtitleFile?.name || ''));
        let subTitleFileName = subtitleFile.type === FILE_TYPES.SRTFILE ?  srtFileName : `${uuidv4()}.${fileNameAndExt?.fileExt}`;
        this.setState({
            progress: 0,
            subTitleFileName :this.replaceSpaceWithUnderscore(subTitleFileName)
        });

        return new Promise((resolve, reject) => {
            this.uploadFile(
                schema,
                this.replaceSpaceWithUnderscore(subTitleFileName),
                (event) => {
                    this.setState({
                        progress: Math.round((100 * event.loadedBytes) / subtitleFile.size),
                    });
                },
                subtitleSasUrl
            ).then((responce) => {
                resolve({ success: true })
            }).catch((error) => {
                reject({ success: false });
            });
        });
    }

    uploadFile = (schema, fileName, onProgress, sasURL) => {
        const { file, subtitleFile, fileType } = schema;
        const fileForUpload = fileType === FILE_TYPES.SUBTITLE ? subtitleFile : file;
        let path = sasURL.split('?');
        let finalPath = `${path[0]}/${fileName}?${path[1]}`;
        const blobServiceClient = new BlockBlobClient(finalPath);
        var blockSize = fileForUpload.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;

        return new Promise((resolve, reject) => {
            try {
                blobServiceClient.uploadData(fileForUpload, {
                    blockSize,
                    onProgress,
                }).then((response) => {
                    console.log(response);
                    toast.success("File Uploaded succesfully.");
                    this.setState(prevState => {
                        return {
                            progress: 0,
                            videoUploadStatus: fileType !== FILE_TYPES.SUBTITLE ? true : prevState.videoUploadStatus,
                            subtitleUploadStatus: fileType === FILE_TYPES.SUBTITLE ? true : prevState.subtitleUploadStatus,
                        }
                    }, () => {
                        resolve({ success: true });
                    });
                }).catch(() => {
                    toast.error(
                        `Could not upload the file!`, {
                        draggablePercent: 60
                    });
                    this.setState(prevState => {
                        return {
                            progress: 0,
                            videoUploadStatus: fileType !== FILE_TYPES.SUBTITLE ? false : prevState.videoUploadStatus,
                            subtitleUploadStatus: fileType === FILE_TYPES.SUBTITLE ? false : prevState.subtitleUploadStatus,
                        }
                    }, () => {
                        reject({ reject: false });
                    });
                });

            } catch (err) {
                toast.error(
                    `Could not upload the file!`, {
                    draggablePercent: 60
                });
                reject({ reject: false });
            }
        })
    }

    render() {
        const {
            selectedFiles,
            progress,
            expanded,
            uploading,
            uploadDisabled
        } = this.state;

        return (
            <div className="container">
                <div className="container-header">
                    <Button onClick={() => window.history.back()} className="back-btn" variant="outlined" startIcon={<ArrowBackIcon />}>
                        Go To Dashboard
                    </Button>
                    <Typography variant="h5" className="txt-center">Asset Upload</Typography>
                </div>
                <div className="upload-container">
                    {selectedFiles?.length === 0 &&
                        (<>
                            <Box className='multi-select'>
                                <label htmlFor="btn-multi-upload">
                                    <input
                                        id="btn-multi-upload"
                                        name="btn-multi-upload"
                                        style={{ display: 'none' }}
                                        accept="video/mp4"
                                        type="file"
                                        multiple
                                        onChange={(e) => this.onSelectFiles(e, FILE_TYPES.VIDEO)} />
                                    <Button
                                        className="btn-choose-multiple"
                                        variant="outlined"
                                        component="span" >
                                        Choose Video Files
                                    </Button>
                                </label>
                            </Box>
                            <Box className='multi-select'>
                                <label htmlFor="btn-audio-upload">
                                    <input
                                        id="btn-audio-upload"
                                        name="btn-audio-upload"
                                        style={{ display: 'none' }}
                                        accept="audio/*"
                                        type="file"
                                        multiple
                                        onChange={(e) => this.onSelectFiles(e, FILE_TYPES.AUDIO)} />
                                    <Button
                                        className="btn-choose-audio"
                                        variant="outlined"
                                        component="span" >
                                        Choose Audio Files
                                    </Button>
                                </label>
                            </Box>
                        </>
                        )
                    }

                    {selectedFiles?.length > 0 &&
                        selectedFiles.map((assetSchema, index) => {
                            return (<>
                                <Accordion key={index} style={{ width: '80%' }} expanded={expanded === `panel${index}`} onChange={this.handleChange(`panel${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            Assest Upload {index + 1}
                                        </Typography>
                                        {/* <Typography sx={{ color: 'text.secondary' }}>Upload video with subtitle</Typography> */}
                                        {(progress > 0 && uploading === `video${index}`) && (
                                            <Box className="progress" display="flex" alignItems="center">
                                                <Box width="100%" mr={1}>
                                                    <BorderLinearProgress variant="determinate" value={progress} />
                                                </Box>
                                                <Box minWidth={35}>
                                                    <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        {
                                            progress == 0 && assetSchema?.fileProgress?.length > 0 &&
                                            <Box minWidth={35}>
                                                <Typography variant="body2">
                                                    {
                                                        assetSchema?.fileProgress.map(
                                                            (status) => {
                                                                return (<span className={`upload-satus ${status.includes('100%') ? 'color-green' : 'color-red'}`}>{status}</span>)
                                                            }
                                                        )
                                                    }
                                                </Typography>
                                            </Box>
                                        }
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box className='display-flex'>
                                            <label htmlFor="btn-upload">
                                                <input
                                                    id="btn-upload"
                                                    name="btn-upload"
                                                    style={{ display: 'none' }}
                                                    accept={assetSchema.fileType === FILE_TYPES.VIDEO ? "video/mp4" : "audio/*"}
                                                    type="file"
                                                    onChange={(e) => this.onChangeFile(e, index)} />
                                                <Button
                                                    className="btn-choose"
                                                    variant="outlined"
                                                    component="span" >
                                                    {assetSchema.fileType === FILE_TYPES.VIDEO ? 'Choose Video File' : 'Choose Audio File'}
                                                </Button>
                                            </label>
                                            <div className="file-name">
                                                {
                                                    <input
                                                        className="filename-input"
                                                        type="text"
                                                        key={index}
                                                        id={'input-filename' + index}
                                                        name={'input-filename' + index}
                                                        value={assetSchema?.fileName}
                                                        placeholder="Enter File Name"
                                                        onChange={(e) => this.handleChangeFileName(e, index)}
                                                    />
                                                }
                                            </div>
                                        </Box>

                                        <div style={{ height: 10 }}></div>
                                        {
                                            assetSchema?.subtitleSchemas?.map((subSchema, subIndex) => {
                                                return (<Box key={subIndex} className='display-flex'>
                                                    <label htmlFor={'btn-sub-upload' + index + subIndex}>
                                                        <input
                                                            key={index + subIndex}
                                                            id={'btn-sub-upload' + index + subIndex}
                                                            name={'btn-sub-upload' + index + subIndex}
                                                            style={{ display: 'none' }}
                                                            type="file"
                                                            accept=".vtt,.srt"
                                                            onChange={(e) => this.onChangeSubtitleFile(e, index, subIndex)} />
                                                        <Button
                                                            className={'btn-sub-choose' + index + subIndex}
                                                            variant="outlined"
                                                            component="span" >
                                                            Choose Subtitle File
                                                        </Button>
                                                    </label>
                                                    <div className="subfile-name">
                                                        <input
                                                            className="filename-input"
                                                            type="text"
                                                            key={index + subIndex}
                                                            disabled={!subSchema?.subtitleFile}
                                                            id={'input-sub-filename' + index + subIndex}
                                                            name={'input-sub-filename' + index + subIndex}
                                                            value={subSchema?.subtitleFileName}
                                                            placeholder="Enter File Name"
                                                            onChange={(e) => this.handleChangeSubtitleFileName(e, index, subIndex)}
                                                        />
                                                        <Select
                                                            labelId={'lng-select-label' + index + subIndex}
                                                            id={'lng-select' + index + subIndex}
                                                            value={subSchema?.languageCode}
                                                            className="lang-select"
                                                            onChange={(e) => this.handleCodeChange(e, index, subIndex)}
                                                            displayEmpty
                                                            variant="outlined"
                                                        >
                                                            <MenuItem value="" disabled>Select Code</MenuItem>
                                                            {this.languageCodes?.map((lng) => {
                                                                return <MenuItem key={lng?.id} value={lng?.code}>{lng?.code}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <IconButton aria-label="delete" color="error" onClick={() => this.removeSubtitle(index, subIndex)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="add" onClick={() => this.addSubTitle(index, subIndex)}>
                                                            <AddCircleOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                                </Box>
                                                )
                                            })
                                        }

                                    </AccordionDetails>
                                </Accordion>
                            </>)
                        })
                    }
                    {selectedFiles?.length > 0 &&
                        <>
                            <div style={{ height: 10 }}></div>
                            <div className='txt-center'>
                                <Button
                                    className="btn-upload"
                                    color="primary"
                                    variant="contained"
                                    component="span"
                                    disabled={!selectedFiles || uploadDisabled}
                                    onClick={this.onUpload}>
                                    Upload
                                </Button>
                            </div>
                        </>
                    }
                    <ToastContainer draggablePercent={60} />
                </div >
            </div>
        );
    }
}
